import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/scss/globals.scss";
import VueGtag from "vue-gtag";
const app = createApp(App);
app
    .use(router)
    .use(VueGtag, {
    config: {
        id: "G-4DDZ7FX81E",
        params: {
            send_page_view: true,
        },
    },
})
    .mount("#app");
