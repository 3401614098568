<template>
  <div class="video-wrap">
    <video autoplay playsinline muted loop>
      <source :src="mp4" type="video/mp4" />
    </video>
  </div>
</template>
<script>
export default {
  name: "MyVideo",
  props: {
    mp4: String,
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_mixins.scss";
video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>
