import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
const titleStart = "beeleev ::";
const DEFAULT_TITLE = titleStart;
const routes = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
        meta: { title: titleStart + " nature. yoga. wellness." },
    },
    {
        path: "/branding",
        name: "Branding",
        meta: { title: titleStart + " branding" },
        component: () => import(/* webpackChunkName: "branding" */ "../views/Branding.vue"),
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    document.title = to.meta.title || DEFAULT_TITLE;
});
router.beforeEach(() => {
    window.scrollTo(0, 0);
});
export default router;
